<template>
   <v-container>
    <v-row>
      <v-col cols="2">
        <v-select
          class="sharedFund-editDialog-fiscalYear"
          id="sharedFundFYSelectField"
          label="Fiscal Years"
          :items="getFiscalYears"
          v-model="editedItem.fiscalYear"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-select
          class="sharedFund-editDialog-type"
          id="sharedFundTypeSelectField"
          label="Fund Type"
          :autofocus="true"
          :items="fundType"
          item-text="description"
          item-value="name"
          v-model="editedItem.type"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="sharedFund-editDialog-fund"
          id="sharedFundFundTextField"
          label="Fund Number"
          v-model="editedItem.fund"
          :rules="[lengthValidation, checkDuplicateValues, ...reqdSelectValidation]"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="sharedFund-editDialog-description"
          id="sharedFundDescriptionTextField"
          label="Description"
          v-model="editedItem.description"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-select
          class="sharedFund-editDialog-bankName"
          id="sharedFundBankNameSelectField"
          label="Bank"
          :items="banks"
          item-text="bankName"
          item-value="_id"
          v-model="editedItem.bank"
        ></v-select>
      </v-col>
      <v-col cols="3">
      <v-text-field
        class="sharedFund-editDialog-totalEncumberedAmount"
        id="sharedFundTotalEncumberedAmountTextField"
        label="Total Encumbered Amount"
        v-if="isFundsOnly"
        v-model.number="editedItem.totalEncumberedAmount"
      ></v-text-field>
      </v-col>
      <v-col cols="3">
        <h5>LastWarrant</h5>
        <div>{{editedItem.lastWarrant}}</div>
      </v-col>
      <v-col cols="3">
        <h5>LastPayment</h5>
        <div>{{editedItem.lastPayment}}</div>
      </v-col>
      <v-col cols="3" />
    </v-row>
    <v-row>
      <v-col cols="3">
      <v-text-field
          class="sharedFund-editDialog-crossReferenceFund"
          id="sharedFundCrossReferenceFundTextField"
          label="Cross Reference Fund"
          v-model="editedItem.crossReferenceFund"
          @blur="padWithZeros"
          @keydown.enter="padWithZeros"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
      <v-text-field
          class="sharedFund-editDialog-altKey"
          id="sharedFundAltKeyTextField"
          v-model="editedItem.altKey"
          label="Alt Key"
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="3">
        <v-text-field
          v-model="editedItem.clearingFund"
          class="sharedFund-editDialog-clearingFund"
          id="sharedFundClearingFundTextField"
          label="Clearing Fund Number"
          v-if="isClearingFund"
        ></v-text-field>
      </v-col> -->
      <v-col cols="3">
        <v-text-field
          class="sharedFund-editDialog-line"
          id="sharedFundLineTextField"
          label="Line"
          v-model.number="editedItem.line"
          type="number"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-checkbox
          class="sharedFund-editDialog-isRequiredUnit"
          id="sharedFundRequiredUnitCheckboxField"
          v-model="editedItem.isRequiredUnit"
          label="Required Unit Code"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          class="sharedFund-editDialog-calculateInterest"
          id="sharedFundCalculateInterestCheckboxField"
          v-model="editedItem.calculateInterest"
          label="Calculate Interest"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          class="sharedFund-editDialog-isExcludedFromExport"
          v-model="editedItem.isExcludedFromExport"
          label="Exclude from Export"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-select
          class="shared-fund-edit-dialog-back-page"
          id="sharedFundBackPageSelectField"
          label="Back Page"
          :items="sharedBackPageEnum"
          item-text="description"
          item-value="name"
          v-model="editedItem.backPage"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import { getFYList, checkDuplicate, validateInputLength } from '../../../util/shared/tmc-global';
import { checkStringEmptiness } from '../../../util/shared/vue-global';
// import { checkStringEmptiness } from '../../../util/shared/vue-global';

const bankCrudKey = 'sharedBank';

export default {
  name: 'FundEditedItem',
  components: {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'fund-dialog',
    },
  },
  created() {
    this.loadBanks({
      criteria: {},
      baseCrudKey: bankCrudKey,
    });
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
    ]),
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
      fundType: (state) => state.enums.SharedFundTypeEnum,
      getFiscalYears: (state) => {
        const regYears = state.shared.fund.regYears || {};
        return getFYList(regYears);
      },
      fundList: (state) => state.base.crud.items.sharedFund,
      sharedBackPageEnum: (state) => {
        const storedBackPage = state.enums.sharedBackPageEnum || [];
        return [
          { name: null, description: '' },
          ...storedBackPage,
        ];
      },
    }),
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    banks() {
      const bankObjs = this.items(bankCrudKey) || [];
      return [{ _id: null, bankName: '' }, ...bankObjs];
    },
    changes() {
      return this.editedItem.changes || [];
    },
    isFundsOnly() {
      return this.warSettingItem && this.warSettingItem.fundsOnly;
    },
    isClearingFund() {
      return this.warSettingItem && this.warSettingItem.clearingFund;
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    checkDuplicateValues() {
      return this.checkDuplicate({
        stateArr: this.fundList,
        fieldName: 'fund',
        val: this.editedItem,
        isNewItem: this.isNewItem,
      });
    },
    lengthValidation() {
      return validateInputLength({
        fieldName: 'fund',
        label: 'Fund',
        val: this.editedItem,
        requiredLength: 4,
      });
    },
    /* Todo - need to update this validation */
    // emptyOrLengthValidation() {
    //   if (checkStringEmptiness(this.editedItem.crossReferenceFund)) return true;
    //   return validateInputLength({
    //     fieldName: 'crossReferenceFund',
    //     label: 'Shared Fund',
    //     val: this.editedItem,
    //     requiredLength: 4,
    //   });
    // },
  },
  methods: {
    checkDuplicate,
    ...mapMutations('base/crud', [
      'setItem',
    ]),
    ...mapActions('shared/bank', [
      'loadBanks',
    ]),
    padWithZeros() {
      if (!checkStringEmptiness(this.editedItem.crossReferenceFund)) {
        const crossRefFundNumbers = [];
        let allCrossRefFund = this.editedItem.crossReferenceFund || '';
        allCrossRefFund = allCrossRefFund.toString().replace(/\s/g, '').split(',');
        allCrossRefFund.forEach((crossRefFundNo) => {
          const paddedFundNum = String(crossRefFundNo).trim().padStart(4, '0');
          crossRefFundNumbers.push(paddedFundNum);
        });
        this.editedItem.crossReferenceFund = crossRefFundNumbers;
      }
      return this.editedItem.crossReferenceFund;
    },
  },

};
</script>
