<template>
  <FundList />
</template>

<script>
import FundList from '@/components/shared/fund/FundList.vue';

export default {
  name: 'Funds',
  components: {
    FundList,
  },
};

</script>
