<template>
  <div>
    <v-container >
      <CRUDCriteria
        :showSearch="false"
        :showReset="true"
      >
        <template v-slot:criteriaRows>
          <v-row>
            <v-col cols="2">
              <v-select
                id="tmc-fund-criteria-fy"
                label="By FY"
                :items="getFiscalYears"
                v-model="criteriaState.fiscalYear"
                @change="searchByCriteria"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                id="tmc-fund-criteria-type"
                label="By Fund Type"
                :items="fundType"
                item-text="description"
                item-value="name"
                v-model="criteriaState.type"
                @change="searchByCriteria"
              ></v-select>
            </v-col>
          </v-row>
        </template>
      </CRUDCriteria>
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :showSelect="true"
        :baseCrudKey="baseCrudKey"
        :headers="headers"
        :createNewItem="newFundDialog"
        :defaultSortBy="sortBy"
        :selectedItems="selectedItems"
        toolbarTitle="Funds"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsertItem"
        @emitSelectedItem="emitSelectedItem"
      >
      <template v-slot:[`additionalToolsAtRight`]="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              id="sharedFundListRenumberLinesBtn"
              class="shared-fund-list-renumber-lines-btn"
              color="primary"
              fab icon
              :disabled="(selectedItems.length) !== 1"
              @click="renumberLines"
            >
              <v-icon large>
                {{ icons.mdiCounter }}
              </v-icon>
            </v-btn>
          </template>
          <span>Renumber Lines</span>
        </v-tooltip>
      </template>
        <template v-slot:[`editedItem`]="{}">
          <FundEditedItem :baseCrudKey="baseCrudKey" />
        </template>
      </CRUDList>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapState,
  mapMutations,
} from 'vuex';
import { mdiCounter } from '@mdi/js';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import FundEditedItem from './FundEditedItem.vue';
import {
  toFiscalYear,
  getFYList,
} from '../../../util/shared/tmc-global';

const getCharacters = (val, num) => {
  const getSubString = (val || '').substring(0, num);
  return getSubString;
};

const getFundTypeAbbreviation = (val) => {
  if (val !== 'COLLECTIONFUND') {
    return getCharacters(val, 1);
  }
  return getCharacters('X', 1);
};

const getHeaders = () => {
  const headers = [
    {
      text: 'Type',
      value: 'type',
      formatter: 'customMethod',
      methodDefinition: getFundTypeAbbreviation,
    },
    { text: 'Fund', value: 'fund' },
    { text: 'Description', value: 'description' },
    { text: 'Bank', value: 'bankName' },
    { text: 'Line', value: 'line' },
    { text: 'LastWarrant', value: 'lastWarrant' },
    { text: 'LastPayment', value: 'lastPayment' },
  ];
  return headers;
};

const getHeadersForPrint = () => [
  { text: 'Fund', value: 'fund' },
  { text: 'Description', value: 'description' },
];

const bankCrudKey = 'sharedBank';

const extractBankName = (item) => item && item.bank && item.bank.bankName;

const extractBankID = (item) => {
  if (item == null) {
    return null;
  }
  if (typeof item.bank === 'string') {
    return item.bank;
  }
  return item.bank && item.bank._id;
};

export default {
  name: 'FundList',
  components: {
    CRUDCriteria,
    CRUDList,
    FundEditedItem,
  },
  data: () => ({
    baseCrudKey: 'sharedFund',
    sortBy: [
      {
        column: 'fund',
        direction: 'asc',
      },
    ],
    icons: {
      mdiCounter,
    },
  }),
  created() {
    // reset criteria while merge flag is true
    this.criteriaState = {};
    this.initCriteria();
    this.loadFiscalYears();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'items',
      'index',
    ]),
    ...mapGetters([
      'todaysDate',
    ]),
    ...mapState({
      storedSelectedItems: (state) => state.shared.fund.selectedItems,
      headers: (state) => {
        if (state.isPrintScreen) {
          return getHeadersForPrint();
        }
        return getHeaders();
      },
      fundType: (state) => {
        const fundTypeVals = state.enums.SharedFundTypeEnum || [];
        return [{ name: null, description: '' }, ...fundTypeVals];
      },
      getFiscalYears: (state) => {
        const regYears = state.shared.fund.regYears || {};
        return getFYList(regYears);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    banks() {
      return this.items(bankCrudKey) || [];
    },
    selectedItems: {
      get() {
        return this.storedSelectedItems;
      },
      set(value) {
        this.setSelectedItems(value);
      },
    },
  },
  methods: {
    ...mapActions(['flashInfo', 'flashSuccess']),
    ...mapActions('shared/fund', [
      'loadFunds',
      'upsertFund',
      'loadFiscalYears',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    ...mapMutations('shared/fund', [
      'setSelectedItems',
    ]),
    ...mapActions('common/renumberLines', [
      'cmnRenumberLines',
    ]),
    initCriteria() {
      this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    },
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteria(baseCrudKey);
      this.loadFunds({
        criteria,
        baseCrudKey,
        postProccessFunds: (dataItems) => dataItems.map((dataItem) => ({
          ...dataItem,
          bankName: extractBankName(dataItem),
        })),
      }).then(() => {
        this.setDefaultSelectedItems();
      });
    },
    newFundDialog() {
      let newItem = {};
      newItem = {
        fiscalYear: toFiscalYear(new Date(this.todaysDate)),
        lastWarrant: 0,
        lastPayment: 0,
      };
      if (this.criteriaState.fiscalYear && this.criteriaState.fiscalYear.length > 0) {
        newItem.fiscalYear = this.criteriaState.fiscalYear;
      }
      return newItem;
    },
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    async upsertItem(itemData) {
      const { baseCrudKey } = this;
      const item = this.saveableEdits(itemData);
      if (!this.isNewItem()) {
        if (!window.confirm('This will affect to all associated Transactions, are you sure!')) {
          return false;
        }
      }
      await this.upsertFund({
        item,
        baseCrudKey,
        postProccessFunds: (dataItems) => dataItems.map((dataItem) => ({
          ...dataItem,
          bankName: extractBankName(dataItem),
        })),
      });
      this.setDefaultSelectedItems();
      return true;
    },
    saveableEdits(item) {
      const updatedItem = { ...item };
      updatedItem.bank = extractBankID(item);
      delete updatedItem.bankName;
      delete updatedItem.lastWarrant;
      delete updatedItem.lastPayment;
      return updatedItem;
    },
    setDefaultSelectedItems() {
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    emitSelectedItem(item) {
      this.selectedItems = item;
    },
    async renumberLines() {
      if (window.confirm('Are you sure you want to renumber lines?')) {
        if (this.selectedItems[0].line !== null) {
          await this.cmnRenumberLines({
            criteria: {
              line: this.selectedItems[0].line,
              fiscalYear: this.selectedItems[0].fiscalYear,
            },
            baseCrudKey: this.baseCrudKey,
          });
          this.handleLoadCRUDList();
          this.flashSuccess(`All the line numbers are now successfully renumbered, for FY ${this.criteriaState.fiscalYear}!`);
        } else {
          this.setDefaultSelectedItems();
          this.flashInfo('The selected transaction does not contain line number!');
        }
      }
      this.setDefaultSelectedItems();
    },
  },
};

</script>
